<template>
  <v-row>
    <v-col
      md="3"
      sm="6"
      cols="12"
      class="mt--30"
      v-for="(service, i) in serviceContent"
      :key="i"
    >
      <div class="standard-service">
        <div class="thumbnai">
          <img :src="service.src" alt="Corporate Images" />
        </div>
        <div class="content">
          <h4>{{ service.title }}</h4>
          
          <p align="justify">
            {{ service.desc }}
          </p>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    data() {
      return {
        serviceContent: [
          {
            src: require("../../assets/images/about/business_th01.png"),
            title: "IT 컨설팅",
            desc: `첨단 기술과 혁신적인 접근을 바탕으로 한 ISP/PI 컨설팅, BI/DW 솔루션, CRM 전략 개발 및 Big Data & Advanced Analytics 분야에서의 IT 인프라 개발을 위한 포괄적인 컨설팅을 제공합니다. 저희의 방법론은 업계에서 검증된 방식을 기반으로 하여 지속적인 혁신과 성장에 중점을 두고 있습니다.`,
          },
          {
            src: require("../../assets/images/about/business_th02.png"),
            title: "ML & AIgorithm",
            desc: `Big Data와 ML 기술에 집중하여 차세대 IT/BT 솔루션을 개발하고, 이종 데이터 관리 및 직관적 시각화로 기술 혁신을 이끕니다. 우리는 산업 발전을 위한 혁신적 해결책 제공과 지속 가능한 기술 진보로 고객의 요구를 충족하고자 합니다.`,
          },
          {
            src: require("../../assets/images/about/business_th03.png"),
            title: "빅데이터 인프라 & IoT",
            desc: `데이터 수집에서 파이프라인 구축, 서비스 실행에 이르기까지, 전체 데이터 거버넌스 체계의 설계 및 구축을 담당합니다. 이와 더불어, IoT 기술을 활용해 외부 데이터를 효율적으로 연동하고 활용하는 전략으로 혁신적인 데이터 플랫폼을 구축, 고객에게 제공합니다.`,
          },
                    {
            src: require("../../assets/images/about/business_th04.png"),
            title: "SI & SM",
            desc: `정보기술 분야에서 쌓아온 깊은 경험과 전문 지식을 토대로, 우리는 효율적이고 효과적인 프로젝트 실행으로 고객의 투자 가치를 극대화합니다. 우리는 기술 혁신과 우수한 고객 경험 제공을 핵심 목표로 삼고, 지속적인 진보와 혁신을 통해 기술 분야에서 선두를 유지하고자 합니다.`,
          },
        ],
      };
    },
  };
</script>

