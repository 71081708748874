<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" :src="logo" />
    </Header>
    <!-- End Header Area -->

    <!-- Start Breadcrump Area  -->
    <div
      class="rn-page-title-area pt--120 pb--190 bg_image business-bg"
      data-black-overlay="6"
    >
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="text-center rn-page-title pt--100">
              <h2 class="heading-title" style="color:#fff;">Business</h2>
              <p>
                (주)데이터엔지니어스랩의 사업을 소개합니다.
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->


    <!-- Start Counterup Area  -->
    <div class="rn-counterup-area ptb--120 bg_color--5">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="text-center section-title">
              <h2 class="heading-title">Business Area</h2>
              <p class="description">
                (주)데이터엔지니어스랩은 데이터에 대한 노하우로 모든 데이터 고민을 해결합니다.<br />
                데이터 전문가들과 상의해보세요.
              </p>
            </div>
          </v-col>
          <ServiceFive />
        </v-row>
      </v-container>
    </div>
    <!-- End Counterup Area  -->

        <!-- Start Brand Area -->
    <div class="rn-brand-area ptb--120 bg_color--1">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="text-center section-title mb--30">
              <h2 class="heading-title">Customers</h2>
            </div>
          </v-col>
        </v-row>
        <Brand />
      </v-container>
    </div>
    <!-- End  Brand Area -->




    
    
    
    
    <Footer />
  </div>
</template>

<script>
  import ServiceFive from "../../components/service/ServiceFive";
  import Header from "../../components/header/Header";
  import About from "../../components/about/About";
  import CounterOne from "../../components/counter/CounterOne";
  import TeamThree from "../../components/team/TeamThree";
  import Testimonial from "../../components/testimonial/Testimonial";
  import Brand from "../../components/brand/Brand";
  import Footer from "../../components/footer/Footer";
  export default {
    components: {
      Header,
      About,
      CounterOne,
      TeamThree,
      Testimonial,
      Brand,
      Footer,
      ServiceFive,
    },
    data() {
      return {
        logo: require("../../assets/images/logo/logo.png"),
        breadcrumbs: [
          {
            text: "Home",
            to: "/",
            disabled: false,
          },
          {
            text: "About",
            to: "",
            disabled: true,
          },
        ],
      };
    },
  };
</script>
